import WritingLabFilledIcon from "@material-design-icons/svg/filled/assignment.svg";
import StudentsFilledIcon from "@material-design-icons/svg/filled/groups.svg";
import SettingsFilledIcon from "@material-design-icons/svg/filled/settings.svg";
import LessonsFilledIcon from "@material-design-icons/svg/filled/video_chat.svg";
import WritingLabIcon from "@material-design-icons/svg/outlined/assignment.svg";
import StudentsIcon from "@material-design-icons/svg/outlined/groups.svg";
import HelpCenterIcon from "@material-design-icons/svg/outlined/help_center.svg";
import SettingsIcon from "@material-design-icons/svg/outlined/settings.svg";
import LessonsIcon from "@material-design-icons/svg/outlined/video_chat.svg";
import * as React from "react";

import { AuthContext } from "~/components/auth/common";
import { getResourcesLink } from "~/constants/common";
import ROUTES from "~/constants/routes";

import HomeIcon from "../../../../../assets/icons/home.svg";
import HomeFilled from "../../../../../assets/icons/homeFilled.svg";
import AccountMenuItem from "./AccountMenuItem";
import { IMenuItemProps } from "./AccountMenuItem/AccountMenuItem";

const MENU_ITEMS: IMenuItemProps[] = [
  {
    href: ROUTES.home,
    title: "Dashboard",
    Icon: HomeIcon,
    IconSelected: HomeFilled
  },
  {
    href: ROUTES.lessons,
    title: "Live tutoring",
    Icon: LessonsIcon,
    IconSelected: LessonsFilledIcon
  },
  {
    href: ROUTES.writingLabs,
    title: "Writing Lab",
    Icon: WritingLabIcon,
    IconSelected: WritingLabFilledIcon
  },
  {
    href: ROUTES.students,
    title: "Students",
    Icon: StudentsIcon,
    IconSelected: StudentsFilledIcon
  }
];

const settingsRoute = {
  href: ROUTES.settings,
  title: "Settings",
  Icon: SettingsIcon,
  IconSelected: SettingsFilledIcon
};

const AccountMenu: React.FC = (): JSX.Element => {
  const { selectedUmbrella, user } = React.useContext(AuthContext);

  return (
    <nav
      className="flex flex-col justify-between flex-grow px-[16px] py-0 pb-[24px]"
      aria-label="Site navigation"
    >
      <div className="flex flex-col gap-[8px] flex-grow">
        {MENU_ITEMS.map(({ title, ...menuItem }) => (
          <AccountMenuItem key={title} title={title} {...menuItem} />
        ))}
      </div>
      <div className="flex flex-col gap-[8px]">
        <AccountMenuItem
          title="Adoption resources"
          href={getResourcesLink(selectedUmbrella.partnerType)}
          Icon={HelpCenterIcon}
          IconSelected={HelpCenterIcon}
          isA
        />
        {user.id !== -1 && <AccountMenuItem {...settingsRoute} />}
      </div>
    </nav>
  );
};

export default AccountMenu;
