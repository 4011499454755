import classnames from "classnames";
import NextLink from "next/link";
import { useRouter } from "next/router";
import * as React from "react";

import LightTooltip from "~/components/core/Tooltip";
import AccountLayoutContext from "~/components/layouts/AccountLayout/AccountLayoutContext";
import { trackEvent } from "~/utils/segment";
import { useLinkWithUmbrella } from "~/utils/useLinkWithUmbrella";

export interface IMenuItemProps {
  href: string;
  title: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  IconSelected: React.FC<React.SVGProps<SVGSVGElement>>;
  isA?: boolean;
}

const AccountMenuItem: React.FC<IMenuItemProps> = props => {
  const linkWithUmbrella = useLinkWithUmbrella(props.href);

  return props.isA ? (
    <AccountMenuItemInner {...props} />
  ) : (
    <NextLink href={linkWithUmbrella || props.href} passHref>
      <AccountMenuItemInner {...props} />
    </NextLink>
  );
};

const AccountMenuItemInner: React.FC<IMenuItemProps> = React.forwardRef<
  HTMLAnchorElement,
  IMenuItemProps
>(({ href, title, Icon, IconSelected, isA }, ref) => {
  const { sideMenuMinimized } = React.useContext(AccountLayoutContext);
  const { pathname } = useRouter();

  const selected = pathname === href;
  const iconClasses = classnames(
    "fill-current h-[24px] w-[24px] flex-shrink-0",
    selected ? "text-cream-100" : "text-navy-900"
  );

  const trackResources = () => trackEvent("view_resources", undefined);

  const handleTouchStart = (event: React.TouchEvent<HTMLAnchorElement>) => {
    const target = event.target as HTMLElement;
    try {
      event.preventDefault();
      target.click();
    } catch (er) {}
  };

  return (
    <LightTooltip
      title={sideMenuMinimized ? title : ""}
      placement="right"
      interactive
      classes={{ tooltip: "paragraph" }}
      enterTouchDelay={0}
    >
      <a
        ref={ref}
        href={href}
        draggable={false}
        aria-current={selected ? "page" : undefined}
        onTouchStart={handleTouchStart}
        className={classnames(
          "z-0 h-[48px] flex flex-row gap-[8px] p-[8px] items-center rounded-[8px] paragraph whitespace-nowrap",
          sideMenuMinimized && "justify-center",
          selected
            ? "font-semibold bg-chalkboard-700 !text-cream-100"
            : "hover:bg-newGrowth-100 focus:bg-newGrowth-100"
        )}
        target={isA ? "_blank" : undefined}
        rel={isA ? "noopener noreferrer" : undefined}
        onClick={title === "Adoption resources" ? trackResources : undefined}
      >
        {selected ? (
          <IconSelected className={iconClasses} />
        ) : (
          <Icon className={iconClasses} />
        )}
        {!sideMenuMinimized ? title : undefined}
      </a>
    </LightTooltip>
  );
});

export default AccountMenuItem;
