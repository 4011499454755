import NextLink from "next/link";
import * as React from "react";

import AccountLayoutContext from "~/components/layouts/AccountLayout/AccountLayoutContext";
import ROUTES from "~/constants/routes";
import { useLinkWithUmbrella } from "~/utils/useLinkWithUmbrella";

import LogoFull from "../../../../assets/images/Logo_PearDeckTutor_noPear.svg";

export const PearLauncher: React.FC<{ className?: string }> = ({
  className
}) => <div className={className} id="psi_launcher" />;

const AccountMenuHeader: React.FC = () => {
  const { sideMenuMinimized } = React.useContext(AccountLayoutContext);
  const linkWithUmbrella = useLinkWithUmbrella(ROUTES.home);

  return (
    <div className="min-h-[170px] flex gap-[8px] w-full items-start self-stretch pt-[24px] pb-[70px] px-[16px] justify-center">
      {!sideMenuMinimized && (
        <>
          <NextLink href={linkWithUmbrella || ROUTES.home} passHref>
            <a className="ml-[36px] flex flex-col paragraph focus:outline-chalkboard700-wide rounded-[4px]">
              <LogoFull className="h-[19px] w-[193px]" />
              <div className="text-[16px] mt-[6px]">Formerly TutorMe</div>
              <div className="fieldLabel mt-[9px]">Admin dashboard</div>
            </a>
          </NextLink>
        </>
      )}
    </div>
  );
};

export default AccountMenuHeader;
